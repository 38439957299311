<template>
  <match-media v-slot="{ mobile }">
    <div class="call-edit-pending-dialog-content">
      <h2 v-if="!mobile" class="call-edit-pending-dialog-content__title">{{ title }}</h2>
      <!--      todo: повторяется шаблон в editDialog, придумать как вынести в отдельный компонент-->
      <v-form-row>
        <v-form-field label="ТИП КОНТАКТА" :error-message="disableFields ? '' : backendErrors.role">
          <v-tabs
            :value="localCallData.role"
            class="calls-create-dialog__role-tabs"
            primary
            @input="updateLocalCallData('role', $event)"
          >
            <v-tab v-for="(role, index) of $options.CALL_ROLE_MAP" :key="index" disabled :name="role.value">
              {{ role.label }}
            </v-tab>
          </v-tabs>
        </v-form-field>
      </v-form-row>
      <v-form-row>
        <v-form-field label="ТЕЛЕФОН КЛИЕНТА" :error-message="disableFields ? '' : backendErrors.phone">
          <template #default="{ validationErrors }">
            <v-input-phone
              :value="localCallData.phone"
              :disabled="disableFields"
              :is-error="!!validationErrors.length"
              @input="updateLocalCallData('phone', $event)"
            >
              <template v-if="successAppend" #append>
                <v-success
                  v-if="successAppend === 'success'"
                  class="call-edit-pending-dialog-content__success-icon"
                ></v-success>
                <v-not-success v-else></v-not-success> </template
            ></v-input-phone>
          </template>
          <template v-if="!disableFields" #error="{ errorMessage }">
            <v-form-user-error :error-message="errorMessage" :user="phoneSearchUser" :call-role="localCallData.role" />
          </template>
        </v-form-field>
      </v-form-row>
      <v-form-row>
        <v-form-field label="ИМЯ КЛИЕНТА" :error-message="disableFields ? '' : backendErrors.name">
          <template #default="{ validationErrors }">
            <v-input
              :value="localCallData.name"
              :disabled="disableFields"
              :is-error="!!validationErrors.length"
              type="text"
              @input="updateLocalCallData('name', $event)"
            />
          </template>
        </v-form-field>
      </v-form-row>
      <v-form-row v-if="isRoleAdmin">
        <v-form-field label="АГЕНТ" :error-message="disableFields ? '' : backendErrors.agent">
          <template #default="{ validationErrors }">
            <v-select
              :value="localCallData.agent"
              label="name"
              :disabled="disableFields"
              append-to-body
              :is-error="!!validationErrors.length"
              :options="agentOptions"
              @input="updateLocalCallData('agent', $event)"
            />
          </template>
        </v-form-field>
      </v-form-row>
      <v-form-row>
        <v-form-field label="ИСТОЧНИК ЛИДА" :error-message="disableFields ? '' : backendErrors.callSource">
          <template #default="{ validationErrors }">
            <v-select
              :value="localCallData.callSource"
              label="name"
              :disabled="disableFields"
              append-to-body
              :is-error="!!validationErrors.length"
              :options="callSourceOptions"
              @input="updateLocalCallData('callSource', $event)"
            />
          </template>
        </v-form-field>
      </v-form-row>
      <v-form-row>
        <v-form-field label="ДАТА" :error-message="disableFields ? '' : backendErrors.callDate">
          <template #default="{ validationErrors }">
            <v-input-date
              :disabled="disableFields"
              :value="localCallData.callDate"
              class="calls-create-dialog__date-input"
              :clearable="false"
              format="DD.MM.YYYY"
              value-type="format"
              :is-error="!!validationErrors.length"
              @input="updateLocalCallData('callDate', $event)"
            />
          </template>
        </v-form-field>
      </v-form-row>
      <v-form-row v-if="localCallData.role === $options.CALL_ROLES.CUSTOMER">
        <v-form-field
          label="ОБЪЕКТ НЕДВИЖИМОСТИ"
          separate-label
          hide-errors
          :error-message="disableFields ? '' : backendErrors.advert"
        >
          <template #default="{ validationErrors }">
            <v-select
              append-to-body
              :value="localCallData.advert"
              label="name"
              :is-error="!!validationErrors.length"
              :options="advertOptions"
              :disabled="disableFields"
              @search="onAdvertSearch"
              @input="updateLocalCallData('advert', $event)"
            ></v-select>
          </template>
        </v-form-field>
      </v-form-row>
      <v-form-row>
        <v-form-field label="КОММЕНТАРИЙ" :error-message="disableFields ? '' : backendErrors.text">
          <template #default="{ validationErrors }">
            <v-textarea
              v-model="localCallData.text"
              :disabled="disableFields"
              :is-error="!!validationErrors.length"
              :rows="3"
              @input="updateLocalCallData('text', $event)"
            />
          </template>
        </v-form-field>
      </v-form-row>
    </div>
  </match-media>
</template>

<script>
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VInput from '@/components/common/VInput.vue'
import VInputPhone from '@/components/common/VInputPhone.vue'
import VSelect from '@/components/common/VSelect.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { MatchMedia } from 'vue-component-media-queries'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import VInputDate from '@/components/common/VInputDate.vue'
import { CALL_ROLE_MAP, CALL_ROLES } from '@/constants/callsRoles'
import VTextarea from '@/components/common/VTextarea.vue'
import VFormUserError from '@/components/form/VFormUserError.vue'
import { authService } from '@/services/http'
import VSuccess from '@/components/icons/VSuccess.vue'
import VNotSuccess from '@/components/icons/VNotSuccess.vue'

export default {
  CALL_ROLE_MAP,
  CALL_ROLES,
  name: 'CallEditPendingDialogFormContentWrapper',
  components: {
    VNotSuccess,
    VSuccess,
    VFormUserError,
    VTextarea,
    VInputDate,
    VTab,
    VTabs,
    MatchMedia,
    VSelect,
    VInputPhone,
    VInput,
    VFormField,
    VFormRow
  },
  props: {
    title: { type: String, default: '' },
    callData: { type: Object, default: () => {} },
    disableFields: { type: Boolean, default: false },
    editMode: { type: Boolean, default: false },
    advertOptions: { type: Array, default: () => [] },
    agentOptions: { type: Array, default: () => [] },
    callSourceOptions: { type: Array, default: () => [] },
    backendErrors: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => {
    return {
      phoneSearchUser: null
    }
  },
  computed: {
    successAppend() {
      if (this.phoneSearchUser) {
        if (typeof this.phoneSearchUser === 'string' || this.phoneSearchUser.role === this.callRole) {
          return 'success'
        }
        return 'not success'
      }
      return ''
    },
    callRole() {
      return this.localCallData.role
    },
    callPhone() {
      return this.localCallData.phone
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    localCallData: {
      get() {
        return this.callData
      },
      set(newValue) {
        this.$emit('change', { ...this.localCallData, ...newValue })
      }
    }
  },
  watch: {
    callPhone: {
      handler(val) {
        if (val.length === 11) {
          authService.select({ phone: val }).then(({ results }) => {
            this.phoneSearchUser = results[0] || 'noMatches'
            if (results[0] && results[0].role === this.localCallData.role) {
              this.localCallData.name = results[0].name
            }
          })
        } else {
          this.phoneSearchUser = null
        }
      }
    }
  },
  methods: {
    onAdvertSearch(query, loading) {
      this.$emit('select-adverts', { query, loading })
    },
    updateLocalCallData(key, value) {
      this.localCallData = { [key]: value }
    }
  }
}
</script>

<style scoped lang="scss">
.call-edit-pending-dialog-content {
  &__title {
    margin-bottom: 20px;
  }

  &__success-icon {
    width: 16px;
  }
}
</style>
