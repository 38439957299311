<template>
  <v-bulk-edit-dialog
    class="calls-bulk-edit-dialog"
    @close="close"
    @reset="updateCallEditModel(isSingleEdit ? calls[0] : {})"
  >
    <template #itemsCount>
      {{ callsCountText }}
    </template>
    <template #body>
      <v-form class="calls-bulk-edit-dialog__form" @submit.prevent="onSubmit">
        <template #default>
          <template v-if="isSingleEdit">
            <v-form-row>
              <v-form-field label="ТИП КОНТАКТА" :error-message="errors.role">
                <v-tabs v-model="callEditModel.role" primary>
                  <v-tab v-for="(role, index) of $options.CALL_ROLE_MAP" :key="index" disabled :name="role.value">
                    {{ role.label }}
                  </v-tab>
                </v-tabs>
              </v-form-field>
            </v-form-row>

            <v-form-row>
              <v-form-field label="ТЕЛЕФОН КЛИЕНТА" :error-message="errors.clientPhone">
                <v-input-phone v-model="callEditModel.phone" :is-error="!!errors.clientPhone">
                  <template v-if="successAppend" #append>
                    <v-success
                      v-if="successAppend === 'success'"
                      class="calls-bulk-edit-dialog__success-icon"
                    ></v-success>
                    <v-not-success v-else></v-not-success> </template
                ></v-input-phone>
                <template #error="{ errorMessage }">
                  <v-form-user-error
                    :error-message="errorMessage"
                    :user="phoneSearchUser"
                    :call-role="callEditModel.role"
                  />
                </template>
              </v-form-field>
            </v-form-row>

            <v-form-row>
              <v-form-field label="ИМЯ КЛИЕНТА" :error-message="errors.clientName">
                <v-input v-model="callEditModel.name" type="text" :is-error="!!errors.clientName" />
              </v-form-field>
            </v-form-row>
          </template>
          <v-form-row v-if="isRoleAdmin">
            <v-form-field label="АГЕНТ">
              <v-select
                v-model="callEditModel.agent"
                label="name"
                append-to-body
                :options="agentOptions"
                :is-error="!!errors.agent"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="ИСТОЧНИК ЛИДА" :error-message="errors.callSource">
              <v-select
                v-model="callEditModel.callSource"
                label="name"
                append-to-body
                :options="callSourceOptions"
                :is-error="!!errors.callSource"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="ДАТА" :error-message="errors.callDate">
              <v-input-date
                v-model="callEditModel.callDate"
                :is-error="!!errors.callDate"
                class="calls-bulk-edit-dialog__form-date-input"
                is-disabled-dates-after-current-date
                :clearable="false"
                format="DD.MM.YYYY"
                value-type="format"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="callEditModel.role === $options.CALL_ROLES.CUSTOMER">
            <v-form-field label="ОБЪЕКТ НЕДВИЖИМОСТИ" separate-label :error-message="errors.advert">
              <v-select
                v-model="callEditModel.advert"
                append-to-body
                :is-error="!!errors.advert"
                label="name"
                :options="advertOptions"
                @search="onAdvertSearch"
              ></v-select>
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="isSingleEdit">
            <v-form-field label="КОММЕНТАРИЙ" :error-message="errors.text">
              <v-textarea v-model="callEditModel.text" :is-error="!!errors.text" :rows="3" />
            </v-form-field>
          </v-form-row>
        </template>
        <template #footer>
          <v-button class="calls-bulk-edit-dialog__form-action" @click="close">Отменить</v-button>
          <v-button
            class="calls-bulk-edit-dialog__form-action"
            :disabled="isSingleEdit && isFieldsNotChanged"
            primary
            type="submit"
            >Применить</v-button
          >
        </template>
      </v-form>
    </template>
    <template v-if="isRoleAdmin" #footer>
      <v-button class="calls-bulk-edit-dialog__action" is-text @click="deleteCalls">{{
        `Удалить ${callsCountActionText}`
      }}</v-button>
    </template>
  </v-bulk-edit-dialog>
</template>

<script>
import VButton from '@/components/common/VButton.vue'
import { advertsService, agentsService, authService, ownersService } from '@/services/http'
import { pluralizeNumeral } from '@/utils/pluralization'
import { formatAdvertName, formatAgentObject } from '@/utils/formatters'
import VForm from '@/components/form/VForm.vue'
import VFormField from '@/components/form/VFormField.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VSelect from '@/components/common/VSelect.vue'
import VBulkEditDialog from '@/components/common/VBulkEditDialog.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import VInputDate from '@/components/common/VInputDate.vue'
import VInput from '@/components/common/VInput.vue'
import VInputPhone from '@/components/common/VInputPhone.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { getFirstErrorForFields, isFieldsNotChanged } from '@/utils/common'
import { CALL_ROLE_MAP, CALL_ROLES } from '@/constants/callsRoles'
import VTextarea from '@/components/common/VTextarea.vue'
import VFormUserError from '@/components/form/VFormUserError.vue'
import VSuccess from '@/components/icons/VSuccess.vue'
import VNotSuccess from '@/components/icons/VNotSuccess.vue'

export default {
  CALL_ROLE_MAP,
  CALL_ROLES,
  name: 'CallsBulkEditDialog',
  components: {
    VNotSuccess,
    VSuccess,
    VFormUserError,
    VTextarea,
    VInputPhone,
    VInput,
    VInputDate,
    VTab,
    VTabs,
    VBulkEditDialog,
    VSelect,
    VFormRow,
    VFormField,
    VForm,
    VButton
  },
  props: {
    calls: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      errors: {},
      callEditModel: {},
      agentOptions: [],
      callSourceOptions: [],
      advertOptions: [],
      phoneSearchUser: null
    }
  },
  computed: {
    isFieldsNotChanged() {
      return isFieldsNotChanged(this.calls[0], this.callEditModel, { isAdmin: this.isRoleAdmin, objectType: 'call' })
    },
    successAppend() {
      if (this.phoneSearchUser) {
        if (typeof this.phoneSearchUser === 'string' || this.phoneSearchUser.role === this.callRole) {
          return 'success'
        }
        return 'not success'
      }
      return ''
    },
    callRole() {
      return this.callEditModel.role
    },
    callPhone() {
      return this.callEditModel.phone
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    callsIds() {
      return this.calls.map(call => call.id)
    },
    callsCount() {
      return this.calls.length
    },
    callsCountActionText() {
      const text = pluralizeNumeral(this.callsCount, 'лид', 'лида', 'лидов')
      return `${this.callsCount} ${text}`
    },
    callsCountText() {
      const text = pluralizeNumeral(this.callsCount, 'лид', 'лида', 'лидов')
      return `${this.callsCount} ${text}`
    },
    isSingleEdit() {
      return this.callsCount === 1
    }
  },
  watch: {
    callPhone: {
      handler(val) {
        if (val.length === 11 && val !== this.calls[0]?.account?.phone) {
          authService.select({ phone: val }).then(({ results }) => {
            this.phoneSearchUser = results[0] || 'noMatches'
            if (results[0] && results[0].role === this.callEditModel.role) {
              this.callEditModel.name = results[0].name
            }
          })
        } else {
          this.phoneSearchUser = null
        }
      }
    },
    calls: {
      immediate: true,
      handler(newCallsArray) {
        if (this.isSingleEdit) {
          this.updateCallEditModel(newCallsArray[0])
        } else {
          this.updateCallEditModel({})
        }
      }
    }
  },
  created() {
    this.selectAgents()
    this.selectCallSources()
    this.selectAdverts()
  },
  methods: {
    onAdvertSearch(query, loading) {
      loading(true)
      this.selectAdverts(query).finally(() => {
        loading(false)
      })
    },
    selectAdverts(query) {
      return advertsService.select(query).then(advertsList => {
        this.advertOptions = advertsList.map(advert => {
          const { name, roundedPrice } = advert
          return {
            ...advert,
            name: `${name} - ${roundedPrice}`
          }
        })
      })
    },
    deleteCalls() {
      this.$emit('delete', this.callsIds)
    },
    updateCallEditModel(model) {
      if (Object.keys(model).length) {
        const { account, ...restModel } = model
        const {
          profile: { agent },
          ...restField
        } = account
        this.callEditModel = {
          ...restModel,
          ...restField,
          agent,
          advert: {
            ...model?.advert,
            name: this.calls[0]?.advert
              ? formatAdvertName(this.calls[0]?.advert, !this.isSingleEdit && !this.calls[0]?.advert)
              : undefined
          }
        }
      } else this.callEditModel = {}
      this.errors = {}
    },
    close() {
      this.updateCallEditModel({})
      this.$emit('close')
    },
    onSubmit() {
      const submitMethod = this.isSingleEdit
        ? agentsService.updateCall(this.callsIds[0], { ...this.callEditModel })
        : agentsService.bulkUpdateCalls(this.callsIds, this.callEditModel)

      submitMethod
        .then(() => {
          this.$emit('submitted')
          this.close()
        })
        .catch(error => {
          this.errors = getFirstErrorForFields(error.details)
        })
    },
    selectAgents() {
      return agentsService.select().then(agentList => {
        this.agentOptions = agentList.map(agent => formatAgentObject(agent))
      })
    },
    selectCallSources() {
      return ownersService.getCallSourcesMap().then(callSourcesList => {
        this.callSourceOptions = callSourcesList
      })
    }
  }
}
</script>
