import { BLUE, RED, LIGHT_GRAY } from '@/constants/tagColors'

export const CALLS_STATUS_PRIMARY = { text: 'Уникальный', color: BLUE, value: 'unique' }
export const CALLS_STATUS_SECONDARY = { text: 'Повторный', color: RED, value: 'repeated' }
export const CALLS_STATUS_ADDITIONAL = { text: 'Доп. заявка', color: LIGHT_GRAY, value: 'additional' }

export const CALLS_STATUSES_GROUP = {
  unique: CALLS_STATUS_PRIMARY,
  repeated: CALLS_STATUS_SECONDARY,
  additional: CALLS_STATUS_ADDITIONAL
}
export const CALLS_TYPES_OPTIONS = [
  { label: 'Все', value: undefined },
  { label: CALLS_STATUS_PRIMARY.text, value: CALLS_STATUS_PRIMARY.value },
  { label: CALLS_STATUS_SECONDARY.text, value: CALLS_STATUS_SECONDARY.value },
  { label: CALLS_STATUS_ADDITIONAL.text, value: CALLS_STATUS_ADDITIONAL.value }
]
