<template>
  <v-table-row class="calls-item">
    <div v-if="hasPending" class="calls-item__overlay-wrapper" @click="overlayClick">
      <p class="calls-item__overlay-text">Отправлен запрос на изменения. <span>Посмотреть</span></p>
    </div>
    <div class="calls-item__content">
      <v-tag-group class="calls-item__call-status" :value="callType" :group="$options.CALLS_STATUSES_GROUP">
        <template #default="{ color, text }">
          <v-tag :color="color"> {{ text }}</v-tag>
        </template>
      </v-tag-group>
      <div class="calls-item__cell calls-item__cell--date">{{ callDate }}</div>
      <v-button v-if="clientLink" class="calls-item__cell calls-item__cell--client-name" is-text :to="clientLink">{{
        clientName
      }}</v-button>
      <div v-else class="calls-item__cell calls-item__cell--client-name">{{ clientName }}</div>

      <router-link :to="agentProfileLink" @click.stop>
        <v-avatar class="calls-item__cell calls-item__cell--agent-avatar" size="small" :src="agent && agent.photo" />
      </router-link>

      <div class="calls-item__cell calls-item__cell--phone">{{ phoneMask }}</div>

      <div class="calls-item__advert">
        <div class="calls-item__cell calls-item__cell--advert-title">{{ advertTitle }}</div>
      </div>
    </div>

    <div class="calls-item__actions" @click.stop>
      <v-popover-actions class="calls-item__popover">
        <v-button-icon><v-icon-dots /> </v-button-icon>
        <template #actions="{ onClick }">
          <v-button-wide :href="`tel:${clientPhone}`">Позвонить</v-button-wide>
          <v-button-wide :href="`https://wa.me/${clientPhone.replace(/^8/, '+7')}`">Написать в WhatsApp</v-button-wide>
          <v-button-wide @click="onClick(edit)">Редактировать</v-button-wide>
          <v-button-wide v-if="isRoleAdmin" @click="onClick(deleteCall)">Удалить</v-button-wide>
        </template>
      </v-popover-actions>
    </div>
  </v-table-row>
</template>

<script>
import VAvatar from '@/components/common/VAvatar.vue'
import VIconDots from '@/components/icons/VDots.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import VTableRow from '@/components/common/VTableRow.vue'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import { formatPhone } from '@/utils/formatters'
import { CALLS_STATUSES_GROUP } from '@/constants/callsStatuses'
import VTagGroup from '@/components/common/VTagGroup.vue'
import VTag from '@/components/common/VTag.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { CALL_ROLES } from '@/constants/callsRoles'
import VButton from '@/components/common/VButton.vue'

export default {
  CALLS_STATUSES_GROUP,
  name: 'CallsListItem',
  components: {
    VButton,
    VTag,
    VTagGroup,
    VAvatar,
    VButtonIcon,
    VIconDots,
    VTableRow,
    VPopoverActions,
    VButtonWide
  },
  inheritAttrs: false,
  props: {
    advert: { type: Object, default: () => {} },
    account: { type: Object, default: () => {} },
    agent: { type: Object, default: () => {} },
    callDate: { type: String, required: true },
    hasPending: { type: Boolean, default: false },
    callType: { type: String, required: true }
  },
  computed: {
    clientLink() {
      const { role, id } = this.account
      if (role && id) {
        const ROLE_LINKS_MAP = {
          [CALL_ROLES.CUSTOMER]: { name: 'customer-edit', params: { customerId: id } },
          [CALL_ROLES.OWNER]: { name: 'owner-edit', params: { ownerId: id } }
        }
        return ROLE_LINKS_MAP[role]
      }
      return null
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    advertTitle() {
      const { name } = this.advert || '-'
      return name
    },
    clientName() {
      const { name } = this.account
      return name || '-'
    },
    agentProfileLink() {
      // TODO add agent id parameter
      return { name: 'users' }
    },
    clientPhone() {
      const { phone } = this.account
      return phone || '-'
    },
    phoneMask() {
      return this.clientPhone ? formatPhone(this.clientPhone) : '-'
    }
  },
  methods: {
    overlayClick() {
      this.$emit('pending-edit')
    },
    deleteCall() {
      this.$emit('delete')
    },
    edit() {
      this.$emit('edit')
    }
  }
}
</script>
