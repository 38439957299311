var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-table-wrapper',_vm._g({staticClass:"calls-table",attrs:{"infinite-id":_vm.infiniteId,"list":_vm.list,"infinite-loading":_vm.infiniteLoading,"table-height":_vm.tableHeight,"with-select":true,"only-single-select":!_vm.isRoleAdmin},scopedSlots:_vm._u([{key:"customColumns",fn:function(){return [_c('el-table-column',{attrs:{"prop":"call_date","label":"Добавлен","sortable":"custom","class-name":"calls-table__cell--date","min-width":"124"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var callDate = ref.row.callDate;
return [_vm._v(" "+_vm._s(callDate)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"is_primary","label":"Статус","class-name":"calls-table__cell--status","min-width":"134"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var callType = ref.row.callType;
return [_c('v-tag-group',{attrs:{"value":callType,"group":_vm.$options.CALLS_STATUSES_GROUP},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var color = ref.color;
var text = ref.text;
return [_c('v-tag',{attrs:{"color":color}},[_vm._v(" "+_vm._s(text))])]}}],null,true)})]}}])}),_c('el-table-column',{attrs:{"prop":"name","sortable":"custom","label":"Имя клиента","class-name":"calls-table__cell--name","min-width":"244","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row_account = ref.row.account;
var name = ref_row_account.name;
var id = ref_row_account.id;
var role = ref_row_account.role;
return [(name && id && role)?_c('v-button',{staticClass:"calls-table__client-link",attrs:{"is-text":"","to":_vm.getClientLinkByRole(role, id)}},[_vm._v(_vm._s(name))]):[_vm._v(_vm._s(name || '-')+" ")]]}}])}),_c('el-table-column',{attrs:{"prop":"phone","label":"Телефон","class-name":"calls-table__cell--phone","min-width":"143"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var phone = ref.row.account.phone;
return [_vm._v(" "+_vm._s(_vm.formatPhone(phone) || '-')+" ")]}}])}),(_vm.isRoleAdmin)?_c('el-table-column',{attrs:{"prop":"agent","sortable":"custom","class-name":"calls-table__cell--agent","label":"Агент","min-width":"200","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var agent = ref.row.agent;
return [(agent)?_c('v-agent',{attrs:{"photo":agent.photo},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(agent.name)+" ")]):_c('span',[_vm._v("-")])]}}],null,false,1957114441)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"advert","label":"Объект","class-name":"calls-table__cell--advert","min-width":"85"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var advert = ref.row.advert;
return [_c('v-adverts-popover',{attrs:{"adverts":[advert]}})]}}])}),_c('el-table-column',{attrs:{"prop":"advert_category","label":"Категория","class-name":"calls-table__cell--adverts-category","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var advert = ref.row.advert;
return [_vm._v(" "+_vm._s((advert && advert.category.name) || '-')+" ")]}}])}),(_vm.isRoleAdmin)?_c('el-table-column',{attrs:{"prop":"call_source","label":"Источник","class-name":"calls-table__cell--call-source","min-width":"190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var callSource = ref.row.callSource;
return [_vm._v(" "+_vm._s((callSource && callSource.name) || '-')+" ")]}}],null,false,2419461629)}):_vm._e()]},proxy:true}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }