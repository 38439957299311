<template>
  <v-popover-wrapper
    class="adverts-popover"
    :disabled="adverts.length === 0"
    is-click-stop
    popover-inner-class="adverts-popover__tooltip-inner"
    popover-inner-arrow-class="adverts-popover__tooltip-inner-arrow"
    placement="top-start"
  >
    <slot> {{ setAdvertsTitle(adverts) }}</slot>

    <template #popover>
      <div class="adverts-popover__list">
        <a
          v-for="(advert, index) in formattedAdverts"
          :key="index"
          target="_blank"
          :href="isNmh && advert.url"
          class="adverts-popover__advert"
        >
          <div class="adverts-popover__advert-photo-wrapper">
            <img v-if="advert.photo" class="adverts-popover__advert-photo" :src="advert.photo" />
            <span v-else>no image</span>
          </div>
          <div class="adverts-popover__advert-info">
            <span class="adverts-popover__advert-id">№ {{ advert.id }}</span>
            <span class="adverts-popover__advert-title">{{ advert.name }}</span>
            <span class="adverts-popover__advert-cost">{{ advert.price }}</span>
          </div>
        </a>
      </div>
    </template>
  </v-popover-wrapper>
</template>

<script>
import VPopoverWrapper from '@/components/common/VPopoverWrapper.vue'
import { pluralizeNumeral } from '@/utils/pluralization'
import { formatMoney } from '@/utils/formatters'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

export default {
  name: 'VAdvertsPopover',
  components: { VPopoverWrapper },
  props: {
    adverts: { type: Array, default: () => [] }
  },
  computed: {
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    formattedAdverts() {
      return this.adverts.map(ad => ({ ...ad, price: `${formatMoney(ad.price)} ₽` }))
    }
  },
  methods: {
    setAdvertsTitle(adverts) {
      const count = adverts.length
      const text = pluralizeNumeral(count, 'объект', 'объекта', 'объектов')
      if (count) {
        return count === 1 ? `№ ${adverts[0].id}` : `${count} ${text}`
      }
      return '-'
    }
  }
}
</script>
