export const SORT_OPTIONS_MAP = [
  { label: 'Сначала новые', value: '-call_date' },
  { label: 'Сначала старые', value: 'call_date' },
  { label: 'от А до Я', value: 'name' },
  { label: 'от Я до А', value: '-name' },
  // todo: временно убрано
  // { label: 'Сначала первичные', value: 'is_primary' },
  // { label: 'Сначала вторичные', value: '-is_primary' },
  { label: 'Сначала без агентов', value: '-agent' },
  { label: 'Сначала с агентами', value: 'agent' }
]
