var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('match-media',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var desktop = ref.desktop;
var tablet = ref.tablet;
var mobile = ref.mobile;
return [_c('aside',{staticClass:"calls__filters-mobile"},[_c('v-drawer',{attrs:{"visible":_vm.filtersVisible},on:{"update:visible":function($event){_vm.filtersVisible=$event}}},[_c('filters-mobile',{attrs:{"default-filters":_vm.queryParams,"count":_vm.params.count},on:{"close":function($event){_vm.filtersVisible = false},"apply-filters":_vm.applyFilters}})],1)],1),(!mobile)?_c('aside',{staticClass:"calls__bulk-edit"},[_c('v-drawer',{attrs:{"position":"right","without-background":"","visible":_vm.bulkEditVisible},on:{"update:visible":function($event){_vm.bulkEditVisible=$event}}},[_c('calls-bulk-edit-dialog',{attrs:{"calls":_vm.selectedCallsEdit},on:{"close":function($event){_vm.bulkEditVisible = false},"delete":_vm.deleteCalls,"submitted":_vm.editCallsSubmit}})],1)],1):_vm._e(),_c('div',{staticClass:"calls"},[_c('div',{ref:"callsTopWrapper",staticClass:"calls__top-wrapper"},[_c('div',{staticClass:"calls__header"},[_c('v-page-title',{staticClass:"calls__title"},[_vm._v(" Мои лиды "),_c('span',{staticClass:"calls__count"},[_vm._v(_vm._s(_vm.params.count))])]),(desktop)?_c('v-suggest-search',{staticClass:"calls__searchrow",attrs:{"min-number-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS,"min-string-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_STRING,"query-string":_vm.queryParams.phone || _vm.queryParams.query,"get-suggestions-method":_vm.getSearchSuggestionsList},on:{"reset":_vm.resetSuggestions},scopedSlots:_vm._u([{key:"searchResultItem",fn:function(ref){
var item = ref.item;
return [_c('customer-suggest-search-item',{attrs:{"customer":item},on:{"click":function($event){return _vm.goToCallerPage(item)}}})]}}],null,true)}):_vm._e(),_c('div',{staticClass:"calls__buttons"},[(!mobile)?_c('v-button',{staticClass:"calls__button calls__button--filters",on:{"click":_vm.toggleFilters}},[_c('v-icon-filters',{staticClass:"calls__button-icon calls__button-icon--filters"}),_vm._v(" Фильтры ")],1):_vm._e(),(_vm.isRoleAdmin)?_c('v-button',{staticClass:"calls__button calls__button--add",attrs:{"primary":""},on:{"click":_vm.addCall}},[_c('v-icon-plus',{staticClass:"calls__icon-plus"}),_vm._v(" Добавить лид ")],1):_vm._e()],1)],1),(mobile)?_c('v-button',{staticClass:"calls__button calls__button--filters",on:{"click":_vm.toggleFilters}},[_c('v-icon-filters',{staticClass:"calls__button-icon calls__button-icon--filters"}),_vm._v(" Фильтры ")],1):_vm._e(),(!desktop)?_c('v-suggest-search',{staticClass:"calls__search",attrs:{"min-number-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS,"min-string-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_STRING,"query-string":_vm.queryParams.phone || _vm.queryParams.query,"get-suggestions-method":_vm.getSearchSuggestionsList},on:{"reset":_vm.resetSuggestions},scopedSlots:_vm._u([{key:"searchResultItem",fn:function(ref){
var item = ref.item;
return [_c('customer-suggest-search-item',{attrs:{"customer":item},on:{"click":function($event){return _vm.goToCallerPage(item)}}})]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"calls__content"},[(!mobile)?[_c('calls-table',{attrs:{"table-height":_vm.tableHeight,"infinite-loading":_vm.infiniteLoading,"list":_vm.list,"infinite-id":_vm.infiniteId},on:{"edit":_vm.editCall,"next-page-request":_vm.nextPageRequest,"change-sort":_vm.applyFilters,"bulk-edit":_vm.bulkEdit}})]:[_vm._l((_vm.list),function(call){return _c('calls-list-item',_vm._b({key:call.id,on:{"delete":function($event){return _vm.deleteCalls([call.id])},"pending-edit":function($event){return _vm.editCall(call)},"edit":function($event){return _vm.editCallMobile(call)}}},'calls-list-item',call,false))}),_c('infinite-loading',{attrs:{"force-use-infinite-wrapper":"","identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteHandler},scopedSlots:_vm._u([{key:"no-more",fn:function(){return [_c('span')]},proxy:true},{key:"no-results",fn:function(){return [_c('span')]},proxy:true}],null,true)})]],2),_c('calls-create-dialog',{attrs:{"visible":_vm.editDialogVisible,"call-data":_vm.callData},on:{"update:visible":function($event){_vm.editDialogVisible=$event},"submitted":_vm.editCallsSubmit}}),_c('call-edit-pending-dialog',{attrs:{"visible":_vm.editPendingDialogVisible,"draft-data":_vm.callDraftData,"call-data":_vm.callData},on:{"update:visible":function($event){_vm.editPendingDialogVisible=$event},"submitted":_vm.editCallsSubmit}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }