<template>
  <div class="customer-suggest-search-item" v-on="$listeners">
    <span class="customer-suggest-search-item__name">{{ customer.name }}</span>
    <span class="customer-suggest-search-item__phone">{{ formatPhone(customer.phone) }}</span>
  </div>
</template>

<script>
import { formatPhone } from '@/utils/formatters'

export default {
  name: 'CustomerSuggestSearchItem',
  props: {
    customer: { type: Object, required: true }
  },
  methods: {
    formatPhone
  }
}
</script>
