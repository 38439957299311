<template>
  <v-select
    class="suggest-search"
    label="name"
    :clear-search-on-select="false"
    :clearable="false"
    :filterable="false"
    append-to-body
    :options="list"
    :loading="loading"
    :is-error="error"
    :dropdown-should-open="dropdownShouldOpen"
  >
    <template #list-header>
      <div class="suggest-search__title">
        <span>Результаты поиска</span>
      </div>
    </template>
    <template #open-indicator>
      <span></span>
    </template>
    <template #search="{ attributes, events, reset }">
      <div class="suggest-search__search-icon-wrapper">
        <v-icon-search v-if="!attributes.value" />
      </div>
      <input v-model="searchQuery" class="vs__search" v-bind="attributes" v-on="events" />
      <button
        v-if="attributes.value && attributes.value.length"
        class="searchrow__button searchrow__button--reset"
        type="button"
        @click="resetSearch(reset)"
      >
        <v-icon-cross class="searchrow__icon-cross" />
      </button>
    </template>
    <template #error>
      <em>Ошибка загрузки данных...</em>
    </template>
    <template #selected-option>
      <span></span>
    </template>
    <template #option="{ option }">
      <slot name="searchResultItem" :item="option"></slot>
    </template>
  </v-select>
</template>

<script>
import VSelect from '@/components/common/VSelect.vue'
import VIconSearch from '@/components/icons/VSearch.vue'
import VIconCross from '@/components/icons/VCross.vue'
import { deleteEverythingExceptNumbers } from '@/utils/formatters'
import { debounce } from '@/utils/common'

export default {
  name: 'VSuggestSearch',
  components: { VSelect, VIconSearch, VIconCross },
  props: {
    minNumberLength: {
      type: Number,
      default: 1
    },
    minStringLength: {
      type: Number,
      default: 1
    },
    queryString: {
      type: String,
      default: ''
    },
    getSuggestionsMethod: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      searchQuery: '',
      fetchType: 'string',
      error: false,
      list: []
    }
  },
  computed: {
    minLength() {
      if (this.isNumber) {
        this.changeFetchType('number')
        return this.minNumberLength
      }
      this.changeFetchType('string')
      return this.minStringLength
    },
    isNumber() {
      return /\d/.test(this.searchQuery[0])
    }
  },
  watch: {
    searchQuery: {
      handler(val) {
        const searchString = this.isNumber ? deleteEverythingExceptNumbers(val) : val
        if (searchString.length >= this.minLength) this.debouncedSearch(searchString)
        else this.$emit('reset')
      }
    }
  },
  created() {
    this.searchQuery = this.queryString
  },
  methods: {
    debouncedSearch: debounce(function(val) {
      this.getList(val)
    }, 300),
    changeFetchType(newFetchType) {
      this.fetchType = newFetchType
    },
    getList(inputValue) {
      this.error = false
      this.loading = true
      this.getSuggestionsMethod({ inputValue, fetchType: this.fetchType })
        .then(list => {
          this.list = list
        })
        .catch(() => {
          this.error = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetSearch(reset) {
      reset()
      this.error = false
      this.searchQuery = ''
      this.$emit('reset')
    },
    dropdownShouldOpen(Select) {
      const searchString = this.isNumber ? deleteEverythingExceptNumbers(Select.search) : Select.search
      return searchString.length >= this.minLength && Select.open
    }
  }
}
</script>
