<template>
  <v-table-wrapper
    :infinite-id="infiniteId"
    :list="list"
    :infinite-loading="infiniteLoading"
    :table-height="tableHeight"
    :with-select="true"
    :only-single-select="!isRoleAdmin"
    class="calls-table"
    v-on="$listeners"
  >
    <template #customColumns>
      <el-table-column
        prop="call_date"
        label="Добавлен"
        sortable="custom"
        class-name="calls-table__cell--date"
        min-width="124"
      >
        <template #default="{row:{callDate}}">
          {{ callDate }}
        </template>
      </el-table-column>
      <!--      todo: временно убрано sortable="custom"-->
      <el-table-column prop="is_primary" label="Статус" class-name="calls-table__cell--status" min-width="134">
        <template #default="{row:{callType}}">
          <v-tag-group :value="callType" :group="$options.CALLS_STATUSES_GROUP">
            <template #default="{ color, text }">
              <v-tag :color="color"> {{ text }}</v-tag>
            </template>
          </v-tag-group>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        sortable="custom"
        label="Имя клиента"
        class-name="calls-table__cell--name"
        min-width="244"
        show-overflow-tooltip
      >
        <template #default="{row:{account:{name, id, role}}}">
          <v-button
            v-if="name && id && role"
            class="calls-table__client-link"
            is-text
            :to="getClientLinkByRole(role, id)"
            >{{ name }}</v-button
          >
          <template v-else>{{ name || '-' }} </template>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="Телефон" class-name="calls-table__cell--phone" min-width="143">
        <template #default="{row:{account:{phone}}}">
          {{ formatPhone(phone) || '-' }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="isRoleAdmin"
        prop="agent"
        sortable="custom"
        class-name="calls-table__cell--agent"
        label="Агент"
        min-width="200"
        show-overflow-tooltip
      >
        <template #default="{row:{agent}}">
          <v-agent v-if="agent" :photo="agent.photo" @click.stop>
            {{ agent.name }}
          </v-agent>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="advert" label="Объект" class-name="calls-table__cell--advert" min-width="85">
        <template #default="{row:{advert}}">
          <v-adverts-popover :adverts="[advert]"></v-adverts-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="advert_category"
        label="Категория"
        class-name="calls-table__cell--adverts-category"
        width="100"
      >
        <template #default="{row:{advert}}">
          {{ (advert && advert.category.name) || '-' }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="isRoleAdmin"
        prop="call_source"
        label="Источник"
        class-name="calls-table__cell--call-source"
        min-width="190"
      >
        <template #default="{row:{callSource}}">
          {{ (callSource && callSource.name) || '-' }}
        </template>
      </el-table-column>
    </template>
  </v-table-wrapper>
</template>

<script>
import VTableWrapper from '@/components/common/VTableWrapper.vue'
import VAgent from '@/components/common/VAgent.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { CALLS_STATUSES_GROUP } from '@/constants/callsStatuses'
import { CALL_ROLES } from '@/constants/callsRoles'
import VTag from '@/components/common/VTag.vue'
import VTagGroup from '@/components/common/VTagGroup.vue'
import { formatPhone } from '@/utils/formatters'
import VButton from '@/components/common/VButton.vue'
import VAdvertsPopover from '@/components/common/VAdvertsPopover.vue'

export default {
  CALLS_STATUSES_GROUP,

  name: 'CallsTable',
  components: {
    VAdvertsPopover,
    VButton,
    VTableWrapper,
    VTagGroup,
    VTag,
    VAgent
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableHeight: {
      type: Number,
      default: 500
    },
    infiniteId: {
      type: Number,
      required: true
    },
    infiniteLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    })
  },
  methods: {
    formatPhone,
    getClientLinkByRole(role, id) {
      const ROLE_LINKS_MAP = {
        [CALL_ROLES.CUSTOMER]: { name: 'customer-edit', params: { customerId: id } },
        [CALL_ROLES.OWNER]: { name: 'owner-edit', params: { ownerId: id } }
      }
      return ROLE_LINKS_MAP[role]
    }
  }
}
</script>

<style lang="scss">
.calls-table {
  &__client-link {
    color: inherit !important;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: underline;
    }
  }
}
</style>
