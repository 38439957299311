<template>
  <textarea
    :class="['v-textarea', isError && 'v-textarea--error']"
    v-bind="$attrs"
    :value="value"
    :cols="cols"
    :rows="rows"
    @input="handleInput"
    v-on="inheritListeners"
  ></textarea>
</template>

<script>
export default {
  name: 'VTextarea',
  inheritAttrs: false,
  props: {
    value: { type: String, default: '' },
    cols: { type: Number, default: 50 },
    rows: { type: Number, default: 5 },
    isError: { type: Boolean, default: false }
  },
  computed: {
    inheritListeners() {
      const { input, ...listeners } = this.$listeners
      return listeners
    }
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>
